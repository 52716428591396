import React, {memo} from 'react';
import Img from 'gatsby-image';

import styled from 'styled-components';

const SmallCards = ({theme, items = []}) => (
  <CardsRow>
    {items.map(({fluid, heading, description}) => (
      <Container theme={theme} key={heading} size={items.length}>
        <ImageContainer>{fluid ? <Img fluid={fluid} alt={heading} style={{width: '100%'}} imgStyle={{objectFit: 'contain'}}/> : ''}</ImageContainer>

        <Content>
          {heading ? <Heading>{heading}</Heading> : ''}
          {description ? <Description>{description}</Description> : ''}
        </Content>
      </Container>
    ))}
  </CardsRow>
);

const CardsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: column;

  @media screen and (min-width: 680px) {
    flex-direction: row;
    margin: 2rem 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem;
  box-sizing: border-box;
  background: ${({theme}) => (theme === 'dark' ? '#f4f4f4' : 'white')};
  border-radius: 10px;
  box-shadow: 9px 9px 6px #00000029;
  width: 100%;
  margin-bottom: 2rem;

  @media screen and (min-width: 680px) {
    width: ${({size}) => (size ? `calc(100% / ${size} - 1rem)` : '100%')};
    margin-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 2rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem - 100px);
  border-left: 2px solid #c5c5c5;
  padding: 1rem;
`;

const Heading = styled.h3`
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0;
  text-align: left;
  text-transform: uppercase;
`;

const Description = styled.p`
  text-align: left;
  color: #707070;
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 0;
`;

export default memo(SmallCards);
