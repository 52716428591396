import React, {memo} from 'react';
import {Link} from 'gatsby';
import Img from 'gatsby-image';

import styled from 'styled-components';
import {Trans} from "gatsby-plugin-react-i18next";

const HorizontalCards = ({theme, items = []}) => (
  <CardRow>
    {items.map(({fixed, fluid, heading, description, link}) => (
      <Container theme={theme} key={heading} link={link}>
        <ImageContainer>
          {fixed ? <Img fixed={fixed} alt={heading}/> : ''}
          {fluid ? <Img fluid={fluid} alt={heading}/> : ''}
        </ImageContainer>

        {heading ? <Heading>{heading}</Heading> : ''}
        {heading ? <Underline/> : ''}

        {description ? <Description>{description}</Description> : ''}

        {link ? (
          <Link to={link} title={heading}>
            <Trans>Learn more</Trans> &gt;
          </Link>
        ) : (
          ''
        )}
      </Container>
    ))}
  </CardRow>
);

const CardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background: ${({theme}) => (theme === 'dark' ? '#f4f4f4' : 'white')};
  padding: 1rem;
  padding-bottom: ${({link}) => (link ? '3rem' : '1rem')};
  border-radius: 10px;
  box-shadow: 9px 9px 6px #00000029;
  position: relative;
  margin-bottom: 2rem;

  a {
    position: absolute;
    bottom: 1.5rem;
    color: #39b54a;
    font-size: 1.25rem;
    font-weight: 700;
    justify-self: flex-end;
  }

  @media screen and (min-width: 680px) {
    width: calc(50% - 1rem);
  }

  @media screen and (min-width: 1040px) {
    width: calc(25% - 2rem);
    margin-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  width: 40%;
`;

const Heading = styled.h3`
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
`;

const Underline = styled.div`
  width: 100px;
  border: 1px solid #30c167;
  box-sizing: border-box;
`;

const Description = styled.p`
  text-align: center;
  color: #707070;
  font-weight: 300;
  font-size: 1.15rem;
`;

export default memo(HorizontalCards);
