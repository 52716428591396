import React, {memo} from 'react';
import styled from 'styled-components';
import {Field} from 'formik';

import Label from './Label';

const RadioInput = ({form, field, ...props}) => {
  return <Input {...field} {...props} />;
};

const Radio = ({id, name, value, required}) => (
  <Label htmlFor={id}>
    <Field type="radio" name={name} id={id} value={value} required={required} component={RadioInput}/>
    {value}
  </Label>
);

const Input = styled.input`
  -webkit-appearance: none;
  outline: none;
  width: 20px;
  height: 20px;
  border: 2px solid #39b54a;
  border-radius: 100%;
  cursor: pointer;
  margin-right: 1rem;
  background: white;

  :checked {
    background: #39b54a;
  }
`;

export default memo(Radio);
