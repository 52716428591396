import React, {memo} from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const ImageSection = ({fluid, fixed, children}) => (
  <FirstSection>
    <ImageWrapper>
      <Img
        fluid={fluid}
        fixed={fixed}
        style={{
          width: '100%',
          height: '100%',
        }}
        imgStyle={{
          objectFit: 'cover',
        }}
        alt="Main image"
      />
    </ImageWrapper>
    {children}
  </FirstSection>
);

const FirstSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  box-shadow: 0px 9px 9px #00000029;
  position: relative;

  @media screen and (min-width: 680px) {
    height: 470px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export default memo(ImageSection);
