import React, {memo} from 'react';
import axios from 'axios';
import {Link} from 'gatsby';
import {Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';

import {Trans} from 'gatsby-plugin-react-i18next';
import {useTranslation} from "react-i18next"

import ErrorComponent from '../components/Form/ErrorComponent';
import Row from '../components/Form/Row';
import Text from '../components/Form/Text';
import Checkbox from '../components/Form/Checkbox';
import Radio from '../components/Form/Radio';
import Textarea from '../components/Form/Textarea';
import SubmitButton from '../components/Form/SubmitButton';
import SubmitPopup from '../components/Form/SubmitPopup';

const initialValues = {
  topic: '',
  name: '',
  email: '',
  message: '',
  privacyPolicy: false,
};

const submitForm = async (values, {setSubmitting, resetForm, setStatus}) => {
  try {
    const {status} = await axios.post(`${process.env.API_URL}/contact`, values);

    setSubmitting(false);

    if (status === 200) {
      resetForm();
      setStatus('SUCCESS');
      return;
    }

    setStatus('ERROR');
  } catch (ex) {
    setStatus('ERROR');
    setSubmitting(false);
  }
};

const ContactForm = ({showTopics = false}) => {
  const {t} = useTranslation()

  const FormSchema = Yup.object().shape({
    topic: showTopics ? Yup.string().required(<Trans>Please select a topic</Trans>) : Yup.string(),
    name: Yup.string().required(<Trans>Please provide a name</Trans>),
    email: Yup.string().email(<Trans>Please provide an email</Trans>).required(<Trans>Please provide a properly formatted email address</Trans>),
    message: Yup.string().required(<Trans>Please provide a message</Trans>),
    privacyPolicy: Yup.boolean().oneOf([true], <Trans>Please accept Privacy Policy</Trans>).required(<Trans>Please accept Privacy Policy</Trans>),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={FormSchema}>
      {({status, setStatus, isSubmitting}) => (
        <Form>
          {showTopics ? (
            <Row style={{marginTop: '1.5rem'}}>
              <Radio name="topic" id="intranets" value={t('Intranets')}/>
              <Radio name="topic" id="customerPlatforms" value={t('Customer platforms')}/>
              <Radio name="topic" id="partnerPlatforms" value={t('Partner portals')}/>
              <Radio name="topic" id="integrationPlatforms" value={t('Integration platforms')}/>
              <ErrorMessage name="topic" component={ErrorComponent}/>
            </Row>
          ) : (
            ''
          )}

          <Text name="name" placeholder={t('Your name')}/>

          <Text type="email" name="email" placeholder={t('Your Email')}/>

          <Textarea name="message" placeholder={t('Message')}/>

          <Checkbox id="privacyPolicy" name="privacyPolicy">
            <Trans>contact form terms link</Trans>{' '}
            <Link to="/privacy-policy" title={t('Privacy Policy')}>
              <Trans>contact form privacy policy</Trans>
            </Link>
          </Checkbox>

          <SubmitButton disabled={isSubmitting}><Trans>Send</Trans></SubmitButton>

          <SubmitPopup
            status={status}
            onCloseHandler={() => {
              setStatus(null);
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default memo(ContactForm);
