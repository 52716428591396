import React, {memo} from 'react';
import styled from 'styled-components';

const Section = ({theme = 'light', id, children}) => (
  <Container theme={theme} id={id}>
    <Content>{children}</Content>
  </Container>
);

const Container = styled.section`
  width: 100%;
  background: ${({theme}) => (theme === 'light' ? 'white' : '#f4f4f4')};
  padding: 1rem;
  box-sizing: border-box;
  position: relative;

  @media screen and (min-width: 680px) {
    padding: 2rem;
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;

  form {
    margin-top: 2rem;
    width: 100%;
  }
`;

export default memo(Section);
