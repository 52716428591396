import React, {memo} from 'react';
import {graphql} from 'gatsby';

import {Trans} from 'gatsby-plugin-react-i18next';
import PageWrapper from '../../containers/PageWrapper';
import ContactForm from '../../modules/ContactForm';
import ImageSection from '../../components/ImageSection';
import Section from '../../components/Section';
import UnderlineHeading from '../../components/UnderlineHeading';
import SectionDescription from '../../components/SectionDescription';
import HorizontalCards from '../../components/HorizontalCards';
import SmallCards from '../../components/SmallCards';

const PartnerPortals = ({data}) => (
  <PageWrapper
    title="Partner Portals"
    description="Keeping your partners informed and providing them with proper tools to fuel your business is not an easy task. Modern and flexible partners, resellers and agents portal can give your company tremendous boost by keeping proper two-way communication and deliver necessary data and tools. CoderyLabs can help you build state of the art solution to manage your partners network."
    urlPath="solutions/partner-platforms"
    keywords={['coderylabs', 'partner platforms', 'partner portals']}
  >
    <ImageSection fluid={data.mainPhoto.childImageSharp.fluid}/>

    <Section>
      <UnderlineHeading><Trans>Partner portals</Trans></UnderlineHeading>
      <SectionDescription>
        <Trans>Partner portals desc</Trans>
      </SectionDescription>
    </Section>

    <Section theme="dark">
      <UnderlineHeading><Trans>Features</Trans></UnderlineHeading>
      <SectionDescription style={{marginBottom: '2rem'}}>
        <Trans>Features partner portals desc</Trans>
      </SectionDescription>

      <SmallCards
        items={[
          {
            fluid: data.dashboards.childImageSharp.fluid,
            heading: <Trans>Dashboards</Trans>,
            description: <Trans>Dashboards desc</Trans>,
          },
          {
            fluid: data.userManagement.childImageSharp.fluid,
              heading: <Trans>User management</Trans>,
              description: <Trans>User management desc</Trans>,
          },
        ]}
      />
      <SmallCards
        items={[
          {
            fluid: data.collaborationTools.childImageSharp.fluid,
              heading: <Trans>Collaboration Tools</Trans>,
              description: <Trans>Collaboration Tools desc</Trans>,
          },
          {
            fluid: data.knowledgeBase.childImageSharp.fluid,
              heading: <Trans>Knowledge Base</Trans>,
              description: <Trans>Knowledge Base desc</Trans>,
          },
        ]}
      />
      <SmallCards
        items={[
          {
            fluid: data.advancedSearch.childImageSharp.fluid,
              heading: <Trans>Advanced Search</Trans>,
              description: <Trans>Advanced Search partner portals desc</Trans>,
          },
          {
            fluid: data.personalisedContent.childImageSharp.fluid,
            heading: <Trans>Personalised Content</Trans>,
              description: <Trans>Personalised Content desc</Trans>,
          },
        ]}
      />
      <SmallCards
        items={[
          {
            fluid: data.chatbot.childImageSharp.fluid,
              heading: <Trans>Chatbot</Trans>,
              description: <Trans>Chatbot partner portals desc</Trans>,
          },
          {
            fluid: data.newsletter.childImageSharp.fluid,
              heading: <Trans>Newsletter</Trans>,
              description: <Trans>Newsletter partner portals desc</Trans>,
          },
        ]}
      />
    </Section>

    <Section>
      <UnderlineHeading>Benefits</UnderlineHeading>
      <SectionDescription>If you’re wondering what will you gain from Intranet based on Liferay, here are main benefits.</SectionDescription>

      <HorizontalCards
        theme="dark"
        items={[
          {
            fluid: data.robustSupportForGlobalNetworks.childImageSharp.fluid,
              heading: <Trans>Robust support for global networks</Trans>,
              description: <Trans>Robust support for global networks desc</Trans>,
          },
          {
            fluid: data.everywhereAndAnytimeAccess.childImageSharp.fluid,
              heading: <Trans>Everywhere and anytime access</Trans>,
              description: <Trans>Everywhere and anytime access desc</Trans>,
          },
          {
            fluid: data.effectiveSeeling.childImageSharp.fluid,
              heading: <Trans>Effective Selling</Trans>,
              description: <Trans>Effective Selling desc</Trans>,
          },
          {
            fluid: data.flexibility.childImageSharp.fluid,
              heading: <Trans>Integration platforms</Trans>,
              description: <Trans>Integration Platforms desc</Trans>,
          },
        ]}
      />
    </Section>

    <Section theme="dark">
      <UnderlineHeading><Trans>Contact Us</Trans></UnderlineHeading>
      <SectionDescription>
        <Trans>Contact Us Hint</Trans>
      </SectionDescription>
      <ContactForm/>
    </Section>
  </PageWrapper>
);

export default memo(PartnerPortals);

export const query = graphql`
  query {
    mainPhoto: file(relativePath: { eq: "solutions/partnerPortals/main_photo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    robustSupportForGlobalNetworks: file(relativePath: { eq: "solutions/partnerPortals/cards/robust_support_for_global_networks.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    everywhereAndAnytimeAccess: file(relativePath: { eq: "solutions/partnerPortals/cards/everywhere_and_any_time_access.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    effectiveSeeling: file(relativePath: { eq: "solutions/partnerPortals/cards/effective_seeling.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    flexibility: file(relativePath: { eq: "solutions/partnerPortals/cards/flexibility.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    dashboards: file(relativePath: { eq: "solutions/partnerPortals/cards/dashboards.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    userManagement: file(relativePath: { eq: "solutions/partnerPortals/cards/user_management.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    collaborationTools: file(relativePath: { eq: "solutions/partnerPortals/cards/collaboration_tools.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    knowledgeBase: file(relativePath: { eq: "solutions/partnerPortals/cards/knowledge_base.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    advancedSearch: file(relativePath: { eq: "solutions/partnerPortals/cards/advanced_search.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    personalisedContent: file(relativePath: { eq: "solutions/partnerPortals/cards/personalised_content.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    chatbot: file(relativePath: { eq: "solutions/partnerPortals/cards/chatbot.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    newsletter: file(relativePath: { eq: "solutions/partnerPortals/cards/newsletter.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
